<template>
  <div class="about">
    <iframe
      id="IFRAMEID"
      src="https://www.it1352.com/Onlinetools/OnlineCompileCommon/7?c_height=100&r_height=100&code=&autoExecute=true"
      style="width: 520px; height: 450px"
    ></iframe>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Challenge",
  components: {

  },
  methods: {}, mounted() {
    var deptObjs = document.getElementById("IFRAMEID").contentWindow
    //判断此元素是否存在
    if (deptObjs != null) {
      //设置该元素的样式或其他属性
      console.log(deptObjs)
      deptObjs.setAttribute('style', ' display:none!important;'); //!important用来提升指定样式条目的应用优先权
    }

  }
};
</script>

<style  scoped>
</style>